import React from 'react'
import { Link } from 'gatsby'
import Swipe from 'react-easy-swipe'

import Nav from '../Nav'
import Footer from '../Footer'
import NavelImage from '../NavelImage'

import formatDate from '../../utils/formatDate'

import logo from '../../assets/images/logo.svg'

const FULLBLEED_TIME = 5

class Fullbleed extends React.Component {
  state = {
    countdown: FULLBLEED_TIME,
    i: 0,
    timer: null,
    mux: 1,
  }
  cycle() {
    this.setState({ countdown: FULLBLEED_TIME })
    this.setState({
      timer: setInterval(() => {
        if (this.state.countdown === 0) {
          this.setState((state) => {
            return { i: (state.i += 1) }
          })
          clearInterval(this.state.timer)
          this.cycle()
        } else this.setState({ countdown: this.state.countdown - 1 })
      }, 1000),
    })
  }
  stopCycle() {
    clearInterval(this.state.timer)
  }
  progress(i) {
    this.setState({ i: i })
    clearInterval(this.state.timer)
    this.cycle()
  }
  componentDidMount() {
    this.cycle()
    this.setState({ mux: this.props.nodes.length })
  }
  componentWillUnmount() {
    this.stopCycle()
  }

  render() {
    let current = this.state.i % this.state.mux

    let fullbleedInvert = this.props.nodes[current].node.frontmatter.fullbleed
      .fullbleedInvert

    return (
      <Swipe
        onSwipeRight={() => this.progress(this.state.i - 1)}
        onSwipeLeft={() => this.progress(this.state.i + 1)}
        tolerance={100}
      >
        <div className="fullbleed">
          <div
            className="fullbleed-article"
            style={{ background: 'black', zIndex: 0 }}
          >
            <Nav
              items={this.props.nav}
              position={`absolute` + (fullbleedInvert ? '-invert' : '')}
            />
            <Footer
              position={`absolute` + (fullbleedInvert ? '-invert' : '')}
            />
          </div>
          <div className="fullbleed-dots-wrapper">
            <div className="fullbleed-dots">
              {this.props.nodes
                .map((node) => node.node)
                .map((node, i, arr) => (
                  <div
                    role="presentation"
                    key={node.id}
                    className={
                      `fullbleed-dot` +
                      (fullbleedInvert ? '-invert ' : ' ') +
                      `${this.state.i % arr.length === i && `active`}`
                    }
                    onClick={() => this.progress(i)}
                  />
                ))}
            </div>
          </div>
          {this.props.nodes
            .map((node) => node.node)
            .map((node, i, arr) => (
              <div
                key={node.id}
                className={`fullbleed-${i} ${
                  this.state.i % arr.length === i
                    ? `fullbleed-visible`
                    : `fullbleed-hidden`
                }`}
              >
                <div
                  className={
                    'fullbleed-article' +
                    (node.frontmatter.primaryImage ? '' : ' no-image')
                  }
                >
                  <Nav
                    items={this.props.nav}
                    position={`absolute` + (fullbleedInvert ? '-invert' : '')}
                  />
                  <Footer
                    position={`absolute` + (fullbleedInvert ? '-invert' : '')}
                  />

                  <Link
                    to={node.fields.slug}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    className="article-image"
                  >
                    {node.frontmatter.primaryImage && (
                      <NavelImage
                        image={node.frontmatter.primaryImage}
                        forceFullSize={true}
                      />
                    )}
                  </Link>
                  <div
                    className={
                      'fullbleed-meta' + (fullbleedInvert ? '-invert' : '')
                    }
                  >
                    <Link
                      to={node.fields.slug}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      <h3 className="fullbleed-title">
                        {node.frontmatter.title}
                      </h3>
                      <div className="fullbleed-subtitle">
                        {node.frontmatter.subtitle}
                      </div>
                    </Link>

                    {node.frontmatter.customDate && (
                      <p className="fullbleed-date">
                        {node.frontmatter.customDate}
                      </p>
                    )}
                    {node.frontmatter.customDate && node.frontmatter.time && (
                      <p className="fullbleed-date">{node.frontmatter.time}</p>
                    )}

                    {!node.frontmatter.customDate && (
                      <p className="fullbleed-date">
                        {formatDate(
                          node.frontmatter.date,
                          node.frontmatter.time
                        )}
                      </p>
                    )}

                    {
                      //<p className="fullbleed-excerpt">{node.frontmatter.teaser}</p>
                    }
                  </div>
                </div>

                <img
                  alt=""
                  key="fullbleed-logo"
                  className="fullbleed-logo"
                  src={logo}
                  style={{
                    filter: !fullbleedInvert ? 'invert(100%)' : 'invert(0%)',
                  }}
                />
              </div>
            ))}
        </div>
      </Swipe>
    )
  }
}

export default Fullbleed
