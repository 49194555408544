import React from 'react'

import is_upcoming from '../../utils/upcoming'

import ListingItem from '../ListingItem'

class EventsListing extends React.Component {
  render() {
    let data = this.props.data
    let committee = this.props.committee
      ? this.props.committee.edges.filter((node) =>
          is_upcoming(node.node.frontmatter.date)
        )
      : []
    let ongoing = this.props.ongoing ? this.props.ongoing.edges : []

    if (this.props.pageContext) var pageContext = this.props.pageContext.event

    let upcoming = []
    let past = []

    if (data !== null) {
      upcoming = data.edges
        .filter((node) => is_upcoming(node.node.frontmatter.date))
        .reverse()
      past = data.edges.filter(
        (node) =>
          !is_upcoming(node.node.frontmatter.date) && node.node.frontmatter.date
      )
    }
    return (
      <div
        className={
          'events-listing' +
          (upcoming.length ? '' : ' no-upcoming') +
          (past.length ? '' : ' no-past')
        }
      >
        {pageContext === 'The Meeting Room' && (
          <div className="post post-content post-body">
            <p>
              From 2017-2019, several exhibitions, curated by Amanda Vincelli,
              often in collaboration with members of the NAVEL Collective, were
              hosted in a small, glass-paneled exhibition space within NAVEL
              called <em>The Meeting Room</em>.
            </p>

            <p>
              Expanding on the notion of a meeting and of meeting rooms in
              general, the exhibitions ranged from sculpture and 2D work to
              video, performance, VR, immersive and/or participatory
              environments.
            </p>

            <p>
              <em>The Meeting Room</em> remained a functional space for
              gathering, discussion and work, while also providing an intimate
              art experience for NAVEL visitors.
            </p>

            <p>
              <em>
                *Note that The Meeting Room project is currently inactive and we
                are no longer accepting submissions.
              </em>
            </p>
          </div>
        )}

        <div className="article-section-wrap upcoming">
          {upcoming.length > 0 && (
            <div className="article-section">Upcoming</div>
          )}
          <div className="article-listing-wrap">
            {upcoming.map(({ node }) => {
              return <ListingItem key={node.id} node={node} />
            })}
          </div>
        </div>

        <div className="article-section-wrap committee">
          {committee.length > 0 && (
            <div className="article-section">Core Programming</div>
          )}
          <div className="article-listing-wrap">
            {committee.map(({ node }) => {
              return <ListingItem key={node.id} node={node} />
            })}
          </div>
        </div>

        <div className="article-section-wrap ongoing">
          {ongoing.length > 0 && <div className="article-section">Ongoing</div>}
          <div className="article-listing-wrap">
            {ongoing.map(({ node }) => {
              return <ListingItem key={node.id} node={node} />
            })}
          </div>
        </div>

        <div className="article-section-wrap past">
          {past.length > 0 && <div className="article-section past">Past</div>}
          <div className="article-listing-wrap">
            {past.map(({ node }) => {
              return <ListingItem key={node.id} node={node} past />
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default EventsListing
