import React from 'react'
import Fullbleed from '../components/Fullbleed'
import ListingItem from '../components/ListingItem'
import EventsListing from '../components/EventsListing'

import TemplateWrapper from '../components/Layout'

import { graphql } from 'gatsby'

const IndexPage = ({ data }) => {
  let news = data.news ? data.news.edges : []

  return (
    <TemplateWrapper>
      <div className="homepage-wrap">
        {data.fullbleed && (
          <Fullbleed
            nodes={data.fullbleed.edges.sort(
              (a, b) =>
                a.node.frontmatter.fullbleed.fullbleedOrder -
                b.node.frontmatter.fullbleed.fullbleedOrder
            )}
            nav={data.nav}
          />
        )}

        {news.length > 0 && <div className="article-section">News</div>}
        <div className="article-listing-wrap">
          {news.map(({ node }) => {
            return <ListingItem key={node.id} node={node} />
          })}
        </div>
        <EventsListing data={data.homepage} ongoing={data.ongoing} />
      </div>
    </TemplateWrapper>
  )
}

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    homepage: allMdx(
      filter: {
        frontmatter: {
          showOnHomepage: { eq: true }
          eventType: { ongoing: { ne: true } }
        }
        fileAbsolutePath: { regex: "/events/" }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            subtitle
            date
            customDate
            time
            showOnHomepage
            eventType {
              committeeProgram
            }
            primaryImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            teaser
          }
          excerpt
        }
      }
    }
    ongoing: allMdx(
      filter: {
        frontmatter: {
          showOnHomepage: { eq: true }
          eventType: { ongoing: { eq: true }, committeeProgram: { ne: true } }
        }
        fileAbsolutePath: { regex: "/events/" }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            subtitle
            date
            customDate
            time
            showOnHomepage
            primaryImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            teaser
          }
          excerpt
        }
      }
    }
    news: allMdx(
      filter: {
        frontmatter: { showOnHomepage: { eq: true } }
        fileAbsolutePath: { regex: "/news/" }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            subtitle
            date
            customDate
            time
            showOnHomepage
            primaryImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            teaser
          }
          excerpt
        }
      }
    }
    fullbleed: allMdx(
      filter: {
        frontmatter: { fullbleed: { showOnFullbleed: { eq: true } } }
        fileAbsolutePath: { regex: "/events/|/news/" }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            subtitle
            date
            customDate
            time
            showOnHomepage
            fullbleed {
              fullbleedOrder
              fullbleedInvert
            }
            primaryImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1440, quality: 80) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            teaser
          }
          excerpt
        }
      }
    }
    nav: allMdx(
      filter: {
        frontmatter: { showInNav: { eq: true } }
        fileAbsolutePath: { regex: "/about/|/support/|/assemblies/" }
      }
      sort: { fields: [frontmatter___navOrder], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            path
            navOrder
          }
          excerpt
        }
      }
    }
  }
`
